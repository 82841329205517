.border-radius {
  border-radius: 20px !important;
}

.primaryColor {
  color: #4b0082 !important;
}

.bg-purple {
  background-color: #4b0082;
}

.navbar {
  z-index: 1 !important;
  background-color: #4b0082;
}

.section-one {
  background-color: #4b0082;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 100px !important;
}

.section-one {
  position: relative;
  overflow: hidden;
}

.wave-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: auto;
}


.custom-shape-divider-bottom-1703835578 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1703835578 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 188px;
  transform: rotateY(180deg);
}

@media (max-width:575px) {
  .custom-shape-divider-bottom-1703835578 svg {

    height: 120px;

  }

  .upside-down-yellow svg {

    height: 120px;

  }
}

.custom-shape-divider-bottom-1703835578 .shape-fill {
  fill: #FFFFFF;
}

.upside-down-yellow {
  fill: #ffd700;
  transform: scaleY(-1);
  overflow-x: hidden;
}

.upside-down-yellow svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 188px;
  /* transform: rotateY(180deg); */
}


.section-two {
  background-color: #fff;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 100px !important;
}

.section-three {
  background-color: #ffd700;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 110px !important;
}

.second__slide__img {
  height: auto !important;
  width: 100%;
}

@media (max-width:575px) {
  .second__slide__img {
    height: auto !important;
    width: 100% !important;
  }
}

.slider__title {
  font-size: 46px !important;
}

.slider__subTitle {
  font-size: 25px;
}

.reminder__input {
  width: 385px !important;
  border: none;
}

.reminder__input:focus {
  outline: none;
}

.submit__btn {
  border: none !important;
}

.pages__links {
  position: absolute;
  left: 7%;
  top: 4%;
}

.pages__link {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .reminder__input {
    width: 285px !important;
  }

  .reminder__input__parent__div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    position: relative !important;
    /* background-color: #4b0082; */
  }

  .slider__item__1 {
    padding-top: 30px !important;
  }

  .slider__item__1 {
    padding: 0px 0px;
  }

  .slider__item__2 {
    padding: 0px 0px;
  }

  .slider__item__3 {
    padding: 0px 0px;
  }

  .slider__title {
    font-size: 30px !important;
  }

  .slider__subTitle {
    font-size: 12px;
  }

  .reminder__details {
    font-size: 12px !important;
  }
}

@media (max-width: 575px) {
  .contents-wrapper {
    text-align: center;
  }

  .slide__img__third {
    margin-top: 0px;
  }

  .discover_sec_img {
    height: 245px !important;
    width: auto;
  }
}

ul {
  list-style-type: none !important;
}

@media (max-width:575px) {
  .about-company {
    text-align: center;
  }

  .links {
    text-align: center;
  }
}